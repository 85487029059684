import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  combineLatest,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { Storage } from '@targx/libs/storage';
import { SIGNIN_USER_MUTATION } from '@targx/graphql-queries/authentication';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private apollo: Apollo
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    Storage.set(environment.KEY_LOCAL_STORAGE, token);
  }

  get accessToken(): string {
    return Storage.get(environment.KEY_LOCAL_STORAGE) ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this.apollo
      .mutate<any>({
        mutation: SIGNIN_USER_MUTATION,
        variables: {
          loginInput: {
            username: credentials.email,
            password: credentials.password,
          },
        },
      })
      .pipe(
        switchMap((result: any) => {
          const response = result.data.AuthLogin;

          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return a new observable with the response
          return of(response);
        }),
        switchMap((response: any) =>
          this._userService.getById(response.userId)
        ),
        tap((response) => {
          console.log(
            '🚀 ~ file: auth.service.ts:97 ~ AuthService ~ tap ~ response',
            response
          );
          // Store the user on the user service
          this._userService.user = response;
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    const decoded = AuthUtils._decodeToken(this.accessToken);

    return combineLatest([this._userService.getById(decoded.userId)]).pipe(
      tap(([user]) => {
        // Store the user on the user service
        this._userService.user = user;

        // Set the authenticated flag to true
        this._authenticated = true;
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    Storage.remove(environment.KEY_LOCAL_STORAGE);
    Storage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
