import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UsersService } from '@core/users/users.service';

@Component({
  selector: 'selected-created-by',
  templateUrl: './selected-created-by.component.html',
  styleUrls: ['./selected-created-by.component.scss'],
})
export class SelectedCreatedByComponent implements OnInit {
  @Input()
  formParent: FormGroup;

  @Input()
  formFieldControl: any;

  constructor(private usersService: UsersService) {}

  users$ = this.usersService.items$;

  ngOnInit(): void {
    console.log('SelectedCreatedByComponent', this.formFieldControl);
  }
}
