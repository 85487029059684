import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  query UserFindOne($where: UserWhereUniqueInput!) {
    UserFindOne(where: $where) {
      id
      roles {
        roleId
        role {
          name
        }
      }
      email
      username
      isActive
    }
  }
`;

export const GET_ALL_USERS = gql`
  query UserFindAll(
    $skip: Int
    $take: Int
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $where: UserWhereInput
  ) {
    UserFindAll(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
      id
      nome
      username
      email
      permissions {
        permission {
          id
          name
        }
      }
      roles {
        role {
          id
          name
        }
      }
    }
  }
`;
