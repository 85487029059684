import gql from 'graphql-tag';

export const GET_ALL_COUNTRIES = gql`
  query CountryFindAll(
    $orderBy: [CountryOrderByWithRelationAndSearchRelevanceInput!]
    $where: CountryWhereInput
    $skip: Int
    $take: Int
  ) {
    CountryFindAll(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
      cca2
      cca3
      createdAt
      createdBy
      id
      name
      updatedAt
      updatedBy
    }
  }
`;

export const GET_ONE_COUNTRY = gql`
  query CountryFindOne($where: CountryWhereUniqueInput!) {
    CountryFindOne(where: $where) {
      cca2
      cca3
      createdAt
      createdBy
      id
      name
      updatedAt
      updatedBy
    }
  }
`;

export const CREATE_ONE_COUNTRY = gql`
  mutation CountryCreate($data: CountryCreateInput!) {
    CountryCreate(data: $data) {
      cca2
      cca3
      createdAt
      createdBy
      id
      name
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_ONE_COUNTRY = gql`
  mutation CountryUpdate(
    $data: CountryUpdateInput!
    $where: CountryWhereUniqueInput!
  ) {
    CountryUpdate(data: $data, where: $where) {
      cca2
      cca3
      createdAt
      createdBy
      id
      name
      updatedAt
      updatedBy
    }
  }
`;
