import gql from 'graphql-tag';

export const SIGNIN_USER_MUTATION = gql`
  mutation AuthLogin($loginInput: LoginInput!) {
    AuthLogin(loginInput: $loginInput) {
      username
      userId
      accessToken
    }
  }
`;

export const RECOVER_PASSWORD_MUTATION = gql`
  mutation AuthRecoverPassword($data: RecoverInput!) {
    AuthRecoverPassword(data: $data) {
      message
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation AuthActivate($data: ActivateInput!) {
    AuthActivate(data: $data) {
      message
    }
  }
`;
