import {
  Directive,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UserService } from '@core/user/user.service';
import { User } from '@core/user/user.types';

@Directive({
  selector: '[requiredRoles]',
})
export class RequiredRolesDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private userService: UserService
  ) {}

  @Input()
  set requiredRoles(roles: string[]) {
    this.userService.user$.subscribe((user: User) => {
      this.decideView(this.userService.hasRole(roles));
    });

    this.decideView(this.userService.hasRole(roles));
  }

  decideView(value: boolean) {
    if (value) {
      this.showComponent();
    } else {
      this.removeComponent();
    }
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];
  }
}
