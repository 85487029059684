import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Role, User } from 'app/core/user/user.types';
import { GET_USER_QUERY } from '@queries/user.graphql';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { AuthUtils } from '@core/auth/auth.utils';
import { Storage } from '@targx/libs/storage';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public _currentUser: User;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private apollo: Apollo) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value

    this._currentUser = value;
    this._user.next(this._currentUser);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this._currentUser &&
      this._currentUser.roles.find((r) => r.role.name === Role.Admin)
    );
  }

  /**
   *  Confirms if user is client
   */
  get isAgent() {
    return (
      this._currentUser &&
      this._currentUser.roles.find((r) => r.role.name === Role.Agent)
    );
  }

  /**
   *  Confirms if user is client
   */
  get isComercial() {
    return (
      this._currentUser &&
      this._currentUser.roles.find((r) => r.role.name === Role.Comercial)
    );
  }

  hasRole(roles: string[]): boolean {
    if (!this._currentUser) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    return roles.some((role: string) =>
      this._currentUser.roles.find((r) => r.role.name === role)
    );
  }

  validateRole(roles: string[]): boolean {
    if (!this._currentUser) {
      return false;
    }

    return roles.some((role: string) =>
      this._currentUser.roles.find((r) => r.role.name === role)
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    const accessToken = Storage.get(environment.KEY_LOCAL_STORAGE);
    const decodedToken = AuthUtils._decodeToken(accessToken);
    return this.apollo
      .watchQuery<User>({
        query: GET_USER_QUERY,
        variables: {
          where: {
            email: decodedToken.email,
          },
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          console.log('[] data', data);
          this.user = data.UserFindOne;
          return data.UserFindOne;
        })
      );
    // return this._httpClient.get<User>('api/common/user').pipe(
    //   tap((user) => {
    //     this._user.next(user);
    //   })
    // );
  }

  getById(id: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: GET_USER_QUERY,
        variables: {
          where: {
            id,
          },
        },
      })
      .valueChanges.pipe(map(({ data }) => data.UserFindOne));
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this.user = response;
      })
    );
  }
}
