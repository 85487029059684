import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from '@core/user/user.service';
import { targxNavigation } from 'app/mock-api/common/navigation/data';
import { FuseNavigationItem } from '@fuse/components/navigation';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private userService: UserService,
    private _router: Router
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, route);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, childRoute);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/', route);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(
    redirectURL: string,
    route: ActivatedRouteSnapshot | Route
  ): Observable<boolean> {
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        // If the user is not authenticated...
        if (!authenticated) {
          // Redirect to the sign-in page
          this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

          // Prevent the access
          return of(false);
        }

        if (route?.data?.role) {
          console.log(
            '🚀 ~ file: auth.guard.ts:101 ~ AuthGuard ~ switchMap ~ route?.data?.role:',
            route?.data?.role,
            this.userService.hasRole(route.data.role)
          );
          if (!this.userService.hasRole(route.data.role)) {
            // Redirect to the sign-in page
            this._router.navigate(['sign-in'], {
              queryParams: { redirectURL },
            });

            // Prevent the access
            return of(false);
          }
        }

        // // check if user has access to the route requested on the given array of routes
        // const hasAccess = targxNavigation.some((item) => {
        //   if (item.children) {
        //     return item.children.filter((child: FuseNavigationItem) => (child.link === redirectURL)).some((child: FuseNavigationItem) => {
        //       if (!child.role) {
        //         return true;
        //       }
        //       return this.userService.hasRole(child.role);
        //     });
        //   }

        //   if (item.link === redirectURL) {
        //     if (!item.role) {
        //       return true;
        //     }
        //     return this.userService.hasRole(item.role);
        //   }
        // });

        // if (!hasAccess) {
        //   // Redirect to the sign-in page
        //   this._router.navigate(['sign-in']);

        //   // Prevent the access
        //   return of(false);
        // }

        // Allow the access
        return of(true);
      })
    );
  }
}
