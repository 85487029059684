import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { Role } from '@core/user/user.types';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/example/example.module').then(
            (m) => m.ExampleModule
          ),
      },
      {
        path: 'suppliers',
        data: {
          role: [Role.Admin, Role.Comercial, Role.Agent],
        },
        loadChildren: () =>
          import('app/modules/admin/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule
          ),
      },
      {
        path: 'clients',
        data: {
          role: [Role.Admin, Role.Comercial, Role.Agent],
        },
        loadChildren: () =>
          import('app/modules/admin/clients/clients.module').then(
            (m) => m.ClientsModule
          ),
      },
      {
        path: 'shipping-companies',
        data: {
          role: [Role.Admin],
        },
        loadChildren: () =>
          import(
            'app/modules/admin/shipping-companies/shipping-companies.module'
          ).then((m) => m.ShippingCompaniesModule),
      },
      {
        path: 'shipping-methods',
        data: {
          role: [Role.Admin],
        },
        loadChildren: () =>
          import(
            'app/modules/admin/shipping-methods/shipping-methods.module'
          ).then((m) => m.ShippingMethodsModule),
      },
      {
        path: 'cargo-ports',
        data: {
          role: [Role.Admin],
        },
        loadChildren: () =>
          import('app/modules/admin/cargo-ports/cargo-ports.module').then(
            (m) => m.CargoPortsModule
          ),
      },
      {
        path: 'species',
        data: {
          role: [Role.Admin],
        },
        loadChildren: () =>
          import('app/modules/admin/species/species.module').then(
            (m) => m.SpeciesModule
          ),
      },
      {
        path: 'shipping',
        data: {
          role: [Role.Comercial],
        },
        loadChildren: () =>
          import('app/modules/admin/shipping/shipping.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'offers',
        data: {
          role: [Role.Agent, Role.Comercial],
        },
        loadChildren: () =>
          import('app/modules/admin/offers/offers.module').then(
            (m) => m.OffersModule
          ),
      },
      {
        path: 'requests',
        data: {
          role: [Role.Comercial],
        },
        loadChildren: () =>
          import('app/modules/admin/requests/requests.module').then(
            (m) => m.RequestsModule
          ),
      },
      {
        path: 'proposal',
        data: {
          role: [Role.Comercial],
        },
        loadChildren: () =>
          import('app/modules/admin/proposals/proposals.module').then(
            (m) => m.ProposalsModule
          ),
      },
    ],
  },
];
