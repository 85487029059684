<div class="fuse-horizontal-navigation-wrapper" *ngIf="currentUser">
  <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
    <ng-container *ngIf="!item?.role || hasRole(item.role)">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <ng-container>
          <!-- Basic -->
          <ng-container *ngIf="item.type === 'basic'">
            <fuse-horizontal-navigation-basic-item
              class="fuse-horizontal-navigation-menu-item"
              [item]="item"
              [name]="name"
            ></fuse-horizontal-navigation-basic-item>
          </ng-container>

          <!-- Branch: aside, collapsable, group -->
          <ng-container
            *ngIf="
              item.type === 'aside' ||
              item.type === 'collapsable' ||
              item.type === 'group'
            "
          >
            <fuse-horizontal-navigation-branch-item
              class="fuse-horizontal-navigation-menu-item"
              [item]="item"
              [name]="name"
            ></fuse-horizontal-navigation-branch-item>
          </ng-container>

          <!-- Divider -->
          <ng-container *ngIf="item.type === 'divider'">
            <fuse-horizontal-navigation-divider-item
              [item]="item"
              [name]="name"
            ></fuse-horizontal-navigation-divider-item>
          </ng-container>

          <!-- Spacer -->
          <ng-container *ngIf="item.type === 'spacer'">
            <fuse-horizontal-navigation-spacer-item
              class="fuse-horizontal-navigation-menu-item"
              [item]="item"
              [name]="name"
            ></fuse-horizontal-navigation-spacer-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
