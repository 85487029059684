import { Injectable } from '@angular/core';
import { User } from '@core/user/user.types';
import { GET_ALL_USERS } from '@queries/user.graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _item$: BehaviorSubject<User | null> = new BehaviorSubject(null);
  private _items$: BehaviorSubject<User[] | null> = new BehaviorSubject(null);

  private _item: User | null;
  private _items: User[] | null;

  constructor(private apollo: Apollo) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get item$(): Observable<User> {
    return this._item$.asObservable();
  }

  get items$(): Observable<User[]> {
    return this._items$.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getUsers(skip: number = 0, take: number = 10000): Observable<User[]> {
    return this.apollo
      .watchQuery<any>({
        query: GET_ALL_USERS,
        variables: {
          skip,
          take,
          orderBy: [
            {
              nome: 'asc',
            },
          ],
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        switchMap(({ data }) => {
          const departments = data.UserFindAll;
          this._items$.next(departments);
          this._items = departments;
          return of(departments);
        })
      );
  }
}
