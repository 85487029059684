import { Injectable } from '@angular/core';
import {
  CREATE_ONE_COUNTRY,
  GET_ALL_COUNTRIES,
  GET_ONE_COUNTRY,
  UPDATE_ONE_COUNTRY,
} from '@queries/countries.graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import {
  CountryCreateInput,
  CountryOrderBy,
  CountryType,
  CountryUpdateInput,
  CountryWhere,
  CountryWhereUnique,
} from './country.type';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private _countries: BehaviorSubject<CountryType[] | null> =
    new BehaviorSubject(null);

  private _country: BehaviorSubject<CountryType | null> = new BehaviorSubject(
    null
  );

  constructor(private readonly _apollo: Apollo) {}

  get country$(): Observable<CountryType | null> {
    return this._country.asObservable();
  }

  get countries$(): Observable<CountryType[] | null> {
    return this._countries.asObservable();
  }

  getCountries(params: {
    where?: CountryWhere;
    orderBy?: CountryOrderBy[];
    skip?: number;
    take?: number;
  }): Observable<CountryType[]> {
    return (
      this._apollo
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .watchQuery<{ CountryFindAll: CountryType[] }>({
          query: GET_ALL_COUNTRIES,
          variables: {
            where: params.where,
            orderBy: params.orderBy,
            skip: params.skip,
            take: params.take,
          },
          // fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(
          switchMap(({ data }) => {
            const countries = data.CountryFindAll;
            this._countries.next(countries);
            return of(countries);
          })
        )
    );
  }

  getCountry(where: CountryWhereUnique): Observable<CountryType> {
    return (
      this._apollo
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .watchQuery<{ CountryFindOne: CountryType }>({
          query: GET_ONE_COUNTRY,
          variables: {
            where,
          },
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(
          switchMap(({ data }) => {
            const country = data.CountryFindOne;
            this._country.next(country);
            return of(country);
          })
        )
    );
  }

  createCountry(countryData: CountryCreateInput) {
    return (
      this._apollo
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .mutate<{ CountryCreate: CountryType }>({
          mutation: CREATE_ONE_COUNTRY,
          variables: {
            data: countryData,
          },
        })
        .pipe(
          map(({ data }) => of(data.CountryCreate))
          /**
           * TODO: Update country list
           */
        )
    );
  }

  updateCountry(params: {
    where: CountryWhereUnique;
    data: CountryUpdateInput;
  }) {
    return (
      this._apollo
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .mutate<{ CountryUpdate: CountryType }>({
          mutation: UPDATE_ONE_COUNTRY,
          variables: {
            where: params.where,
            data: params.data,
          },
        })
        .pipe(
          map(({ data }) => of(data.CountryUpdate))
          /**
           * TODO: Update country list
           */
        )
    );
  }
}
