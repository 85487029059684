/* tslint:disable:max-line-length */
import { Role } from '@core/user/user.types';
import { FuseNavigationItem } from '@fuse/components/navigation';

export const targxNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'heroicons_outline:home',
    type: 'basic',
    link: '/dashboard',
  },
  {
    id: 'proposal',
    title: 'Proposals',
    icon: 'heroicons_outline:currency-dollar',
    type: 'basic',
    link: '/proposal',
    role: [Role.Admin],
    badge: {
      title: 'new',
      classes: 'ml-1 px-2 bg-red-600 text-white rounded-full',
    },
  },
  {
    id: 'offers',
    title: 'Offers',
    type: 'basic',
    link: '/offers',
    icon: 'heroicons_outline:shopping-bag',
    role: [Role.Comercial, Role.Agent],
    badge: {
      title: 'updated',
      classes: 'ml-1 px-2 bg-blue-600 text-white rounded-full',
    },
  },
  {
    id: 'requests',
    title: 'Request',
    type: 'basic',
    link: '/requests',
    icon: 'heroicons_outline:shopping-cart',
    role: [Role.Comercial],
    badge: {
      title: 'updated',
      classes: 'ml-1 px-2 bg-blue-600 text-white rounded-full',
    },
  },

  {
    id: 'fretes',
    title: 'Shipping',
    type: 'basic',
    link: '/shipping',
    icon: 'heroicons_outline:truck',
    role: [Role.Comercial],
  },

  {
    id: 'crm',
    title: 'CRM',
    type: 'group',
    icon: 'heroicons_outline:user-group',
    role: [Role.Admin, Role.Comercial, Role.Agent],
    children: [
      {
        id: 'crm.clients',
        title: 'Customers',
        type: 'basic',
        link: '/clients',
        role: [Role.Admin, Role.Comercial, Role.Agent],
        icon: 'heroicons_outline:users',
      },
      {
        id: 'crm.suppliers',
        title: 'Suppliers',
        type: 'basic',
        link: '/suppliers',
        role: [Role.Admin, Role.Comercial, Role.Agent],
        icon: 'heroicons_outline:identification',
      },
    ],
  },
  {
    id: 'divider-2',
    type: 'divider',
  },
  {
    id: 'configs',
    title: 'Settings',
    type: 'group',
    icon: 'heroicons_outline:adjustments',
    role: [Role.Admin],
    children: [
      {
        id: 'configs.species',
        title: 'Species',
        type: 'basic',
        link: '/species',
        role: [Role.Admin],
        icon: 'heroicons_outline:globe',
      },
      {
        id: 'configs.ports',
        title: 'Cargo Ports',
        type: 'basic',
        link: '/cargo-ports',
        role: [Role.Admin],
        icon: 'iconsmind:ship',
      },
      {
        id: 'configs.containers',
        title: 'Shipping Methods',
        type: 'basic',
        link: '/shipping-methods',
        role: [Role.Admin],
        icon: 'iconsmind:box_close',
      },
      {
        id: 'configs.companhias',
        title: 'Shipping Companies',
        type: 'basic',
        link: '/shipping-companies',
        role: [Role.Admin],
        icon: 'heroicons_outline:library',
      },
    ],
  },
];

export const defaultNavigation: FuseNavigationItem[] = targxNavigation;
export const compactNavigation: FuseNavigationItem[] = targxNavigation;
export const futuristicNavigation: FuseNavigationItem[] = targxNavigation;
export const horizontalNavigation: FuseNavigationItem[] = targxNavigation;
