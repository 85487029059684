import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'environments/environment';
import { enableProdMode } from '@angular/core';

Sentry.init({
  dsn: 'https://62a563654f3249c1a5e0ee571d1438f2@o351513.ingest.sentry.io/4505238701277184',
  environment: 'production',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        'https://erp.tradingtimber.com/',
        'https://erp-api.tradingtimber.com/graphql',
        'https://erp-api-dev.tradingtimber.com/graphql',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
