/* eslint-disable @typescript-eslint/naming-convention */
export interface User {
  id: string;
  name: string;
  email: string;
  username: string;
  avatar?: string;
  status?: string;
  isActive?: boolean;

  [key: string]: any;
}

export enum Role {
  Admin = 'admin',
  Agent = 'agente',
  Comercial = 'comercial',
}
