// -----------------------------------------------------------------------------------------------------
// @ STORAGE UTILITIES
//
// Methods are derivations of the Localstorage helper service methods with encripation
// -----------------------------------------------------------------------------------------------------

import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

export class Storage {
  private static _passPhrase = environment.passphrase;

  private static storageEnviorment = !environment.production
    ? localStorage
    : sessionStorage;

  /**
   * Constructor
   */
  constructor() {}

  public static encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, Storage._passPhrase).toString();
  }

  public static _decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, Storage._passPhrase).toString(
      CryptoJS.enc.Utf8
    );
  }

  static set(key: string, value: string) {
    Storage.storageEnviorment.setItem(key, Storage.encrypt(value));
  }

  static get(key: string): string {
    if (Storage.storageEnviorment.getItem(key)) {
      return Storage._decrypt(Storage.storageEnviorment.getItem(key));
    }

    return null;
  }

  static remove(key: string) {
    Storage.storageEnviorment.removeItem(key);
  }

  static clear() {
    Storage.storageEnviorment.clear();
  }
}
